body {
    font-family: 'Inter', sans-serif;
}

.navbar-fixed {
    @apply fixed z-[9999] bg-white bg-opacity-70 backdrop-blur-sm dark:bg-dark dark:bg-opacity-50;
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.2);
}

.hamburger-line {
    @apply my-2 block h-[2px] w-[30px] bg-dark dark:bg-white;
}

.hamburger-active > span:nth-child(1) {
    @apply rotate-45;
}

.hamburger-active > span:nth-child(2) {
    @apply scale-0;
}

.hamburger-active > span:nth-child(3) {
    @apply -rotate-45;
}

#dark-toggle:checked ~ label div.toggle-circle {
    @apply translate-x-3;
}

.instagram {
    background: #d6249f;
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
}

.swiper {
    width: 100%;
    height: 100%;
    padding: 1rem 0 2.5rem 0;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}
